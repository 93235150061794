body{
  background-color: #000;
  overflow:auto;
}

input{
  border:none;
  width:100px;
  outline:none;
  padding:"6px 4px";
  font-size:14px;
  text-align: center;
  background: transparent;
  color: #dcdcdc;
}

.game-context-input{
  background:transparent;
  width:35px;
  padding:"3px 2px";
  font-size:12px;
  color: white;
}
.game-tables{
  margin: "0 20px";
  background-color:"transparent"; 
  color:"white"; 
  border:"1px solid #cccccc"
}
